import React from "react";

export const ErrorPage = () => {
    return (
        <div>
            <h1>Error 404!</h1>
        </div>
    );
};

const NotFound = () => {
    return (
        <div>
            <h4>Oops! nothing here!</h4>
        </div>
    );
};

export { NotFound };
export default ErrorPage;