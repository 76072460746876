import React from 'react';
import ReactDOM from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import './bootstrap-icons/font/bootstrap-icons.css';
import './styles/Global.css';
import App from './App';


// importa paginas de router
import Home from "./routes/Home";
import About from "./routes/About";
import Contact from "./routes/Contact";
import Precos from './routes/Precos';


// Paginas que precisa de privilegio -"login"

import Main from "./routes/Main";


import ErrorPage, { NotFound } from "./routes/ErrorPage";

import { createBrowserRouter, RouterProvider } from 'react-router-dom'


const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: '/',
        element: < Home />,
      },
      {
        path: '/about',
        element: <About />,
      },
      {
        path: '/contact',
        element: <Contact />,
      },
      {
        path: '*',
        element: <NotFound />,
      },
      {
        path: '/precos',
        element: <Precos />,
      },
      {
        path: '/main',
        element: <Main />
      }
    
    ]
  },
])


ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>,
  document.getElementById('root')
)



