import React from "react";

export const About = () => {
    return (
        <div style={{ height: '100vh' }}>
            <div style={{marginLeft: '30px', marginRight: '30px', marginTop: '20px'}}>
                <p>O Chattzo está lançando uma oferta especial para sócios fundadores! Como sócio fundador, você terá acesso a uma série de benefícios exclusivos que incluem:</p>

                <ul>
                    <li><strong>Descontos Exclusivos:</strong> Aproveite descontos significativos nos nossos serviços que estarão disponíveis apenas para sócios fundadores. Enquanto os demais clientes pagam o valor integral, você desfrutará de preços reduzidos.</li>
                    <li><strong>Ofertas Exclusivas:</strong> Receba ofertas e promoções especiais que não estarão disponíveis para o público em geral. Isso inclui acesso antecipado a novos recursos e funcionalidades.</li>
                    <li><strong>Participação no Desenvolvimento:</strong> Tenha a oportunidade de influenciar o desenvolvimento do Chattzo. Suas sugestões e feedback serão levados em consideração para futuras atualizações e melhorias.</li>
                    <li><strong>Exclusividades:</strong> Benefícios adicionais como suporte prioritário, convites para eventos exclusivos e muito mais.</li>
                </ul>

                <p>Apoie o desenvolvimento do Chattzo e ajude a moldar o futuro da automação de atendimento no WhatsApp! Junte-se a nós como sócio fundador e aproveite essas vantagens incríveis.</p>

            </div>
        </div>
    );
}

export default About;