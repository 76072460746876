import React, { useEffect, useCallback } from "react";
import { Link, useLocation } from 'react-router-dom';
import LogoSvg from '../svg/logo.svg';


const Navbar = () => {
  const location = useLocation();
  
  const getPageTitle = useCallback(() => {
    switch (location.pathname) {
      case '/':
        return 'Chattzo';
      case '/precos':
        return 'Chattzo - Preços';
      case '/about':
        return 'Chattzo - Sobre';
      case '/contact':
        return 'Chattzo - Contato';
      case '/register':
        return 'Chattzo - Registrar';
      case '/login':
        return 'Chattzo - Login';
      default:
        return 'Chattzo';
    }
  }, [location.pathname]);

  useEffect(() => {
    document.title = getPageTitle();
  }, [location.pathname, getPageTitle]);

  return (
    <nav className="navbar navbar-expand-lg background-primary">
      <div className="container-fluid">
        <a className="navbar-brand" href="/">
          <img src={LogoSvg} alt="Seu SVG" width="50px" height="50px" className="font-bold" />
          <span className="text-color">Chattzo</span>
        </a>
        <button className="navbar-toggler toggler-Nav text-color" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <i class="bi bi-list text-color fs-1"></i>
        </button>

        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav mx-auto mb-2 mb-lg-0">
            <li className="nav-item">
              <Link className="nav-link font-bold text-color" aria-current="page" to="/">Inicio</Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link font-bold text-color" to="/precos">Preços</Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link font-bold text-color" to="/about">Sobre</Link>
            </li>
            {/* <li className="nav-item">
              <Link className="nav-link font-bold text-color" to="/contact">Contato</Link>
            </li> */}
          </ul>
          <ul className="navbar-nav">
            {/* 
            <li className="nav-item out-button-green">
              <Link className="nav-link out-text-green" to="/register">Registrar</Link>
            </li>
            */}
            <li className="nav-item button-green">
              <Link className="nav-link text-btn-10 btn-text-white" to="/login"><i class="bi bi-whatsapp" style={{fontSize: '20px' }}></i> Contato</Link>
            </li>
          </ul> 
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
