import './index.js';
import React, { useState, useEffect } from "react";
import Navbar from './components/Navbar';
import Footer from './components/Footer.js';
import { Outlet, useLocation } from 'react-router-dom';


function App() {
  // Recuperar o valor do localStorage quando o componente for montado
  const [isDarkMode, setIsDarkMode] = useState(() => {
    const savedMode = localStorage.getItem('isDarkMode');
    return savedMode !== null ? JSON.parse(savedMode) : false;
  });

  const toggleTheme = () => {
    setIsDarkMode(prevMode => {
      const newMode = !prevMode;
      // Salvar o novo estado no localStorage
      localStorage.setItem('isDarkMode', JSON.stringify(newMode));
      return newMode;
    });
  };

  const location = useLocation();

  useEffect(() => {
    const backgroundPrimary = document.querySelectorAll(".background-primary");
    const backgroundDefaulth = document.querySelectorAll(".background-defaulth");
    const textColor = document.querySelectorAll(".text-color");

    if (isDarkMode) { // Aplica o tema escuro
      document.body.classList.add("bg-defaulth-dark");
      document.body.classList.remove("bg-defaulth-light");

      backgroundPrimary.forEach(element => {
        element.style.background = "#28292F"; // Define o fundo escuro
      });
      textColor.forEach(element => {
        element.style.color = "#FFFFFF"; // Define a cor do texto para branco
      });
      backgroundDefaulth.forEach(element => {
        element.style.background ="#1F1F25";
      });
    } else { // Aplica o tema claro
      document.body.classList.add("bg-defaulth-light");
      document.body.classList.remove("bg-defaulth-dark");

      backgroundPrimary.forEach(element => {
        element.style.background = "#F4F3F7"; // Define o fundo claro
      });
      textColor.forEach(element => {
        element.style.color = "#1A1A1A"; // Define a cor do texto para preto
      });
      backgroundDefaulth.forEach(element => {
        element.style.background ="#D7DAE1";
      });
    }
  }, [isDarkMode, location]); // Dependência do efeito: executa quando isDarkMode ou location mudam

  return (
    <div className={`App ${isDarkMode ? "bg-default-dark" : "bg-default-light"}`}>
      <button onClick={toggleTheme} className="Btn-theme">
      {isDarkMode ? (
          <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-moon-fill " viewBox="0 0 16 16">
            <path d="M6 .278a.77.77 0 0 1 .08.858 7.2 7.2 0 0 0-.878 3.46c0 4.021 3.278 7.277 7.318 7.277q.792-.001 1.533-.16a.79.79 0 0 1 .81.316.73.73 0 0 1-.031.893A8.35 8.35 0 0 1 8.344 16C3.734 16 0 12.286 0 7.71 0 4.266 2.114 1.312 5.124.06A.75.75 0 0 1 6 .278"/>
          </svg>
        ) : (
          <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-brightness-high-fill" viewBox="0 0 16 16">
            <path d="M12 8a4 4 0 1 1-8 0 4 4 0 0 1 8 0M8 0a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 0m0 13a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 13m8-5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2a.5.5 0 0 1 .5.5M3 8a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2A.5.5 0 0 1 3 8m10.657-5.657a.5.5 0 0 1 0 .707l-1.414 1.415a.5.5 0 1 1-.707-.708l1.414-1.414a.5.5 0 0 1 .707 0m-9.193 9.193a.5.5 0 0 1 0 .707L3.05 13.657a.5.5 0 0 1-.707-.707l1.414-1.414a.5.5 0 0 1 .707 0m9.193 2.121a.5.5 0 0 1-.707 0l-1.414-1.414a.5.5 0 0 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .707M4.464 4.465a.5.5 0 0 1-.707 0L2.343 3.05a.5.5 0 1 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .708"/>
          </svg>
        )}
      </button>
       <Navbar /> {/*Renderiza o Navbar */}
      <Outlet /> {/* Renderiza as rotas aninhadas */}
      <Footer /> {/* Renderiza o Footer */}
    </div>
  );
}

export default App; // Exporta o componente App como padrão
