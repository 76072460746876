import React from "react";
import { ReactComponent as Check } from '../bootstrap-icons/check.svg'; // forma de chamar um icone é utilizando o endereço onde encontrará o icone, vá no site do Bootstrap, veja o nome que está escrito para utilizar o icone desejado
import { ReactComponent as Recomended } from '../bootstrap-icons/stars.svg';
import "../styles/Precos.css";
const Precos = () => {
    return (
        <div>
            <div className='margin-left-40 margin-right-40 margin-top-40'>
                <div className='center-horizontal'>
                    <div>
                        <h1 className=""><b>Preços</b></h1>
                        <h4 className='margin-top-20'>Compreendo que você está interessado em uma solução para conectar o WhatsApp à nuvem e gerenciar suas conversas de forma mais conveniente. Nossa plataforma segura permite que você tenha o controle total sobre suas interações com os clientes, independentemente do estado do seu celular ou da disponibilidade de internet.</h4>
                    </div>
                        <h3 className='margin-top-60'><b>Pacote Básico</b></h3>
                        <div className='bloco-container-price'>
                        <div className='background-primary bg-square margin-top-40'>
                            {/* valores */}
                            <p style={{ lineHeight: '32px', marginTop: '25px' }}>
                                <span style={{ fontSize: '14px', verticalAlign: 'top', marginBottom: '15px' }}>R$</span>
                                <span style={{ fontSize: '32px', fontWeight: 'bold' }}>79</span>
                                <span style={{ fontSize: '14px', fontWeight: 'bold' }}>,99</span>
                                <span style={{ fontSize: '20px' }}>/mês</span>
                                <br></br><br></br>
                                <p className='margin-top-30'>
                                    <Check className='check-price' /> Atendente 24horas
                                    <div className='hr-light'></div>
                                    <Check className='check-price' /> Menu com opções
                                    <div className='hr-light'></div>
                                    <Check className='check-price' style={{ marginRight: '10' }} /> Envio em massa!
                                    <div className='hr-light'></div>
                                </p>
                                <button className='margin-top-40 button-green'><span className="btn-text-white">COMPRAR</span></button>
                            </p>
                        </div>

                        <div className='background-primary bg-square margin-top-40 emp-value'>
                            {/* valores */}
                            <div className='ofer-ndv'>
                                <Recomended className='color-primary-light' style={{ width: '30px', height: '30px', marginRight: '30px', marginTop: '10px' }} />
                            </div>

                            <p style={{ lineHeight: '32px', marginTop: '25px' }}>
                                <span style={{ fontSize: '14px', verticalAlign: 'top', marginBottom: '15px' }}>R$</span>
                                <span style={{ fontSize: '32px', fontWeight: 'bold' }}>172</span>
                                <span style={{ fontSize: '14px', fontWeight: 'bold' }}>,70</span>
                                <span style={{ fontSize: '20px' }}>/ano</span>
                                <p>
                                    <s className='disabled-color-light' style={{ fontSize: '20px', fontWeight: 'bold' }}>
                                        R$215,88
                                    </s>
                                </p>
                                <p className='margin-top-30'>
                                    <Check className='check-price' /> Atendente 24horas
                                    <div className='hr-light'></div>
                                    <Check className='check-price' /> Menu com opções
                                    <div className='hr-light'></div>
                                    <Check className='check-price' style={{ marginRight: '10' }} /> Envio em massa!
                                    <div className='hr-light'></div>
                                </p>
                                <button className='margin-top-40 button-green'><span className="btn-text-white">COMPRAR</span></button>
                            </p>
                        </div>
                    </div>


                </div>
            </div>

            <h5 className='margin-top-20 exp'>
                1. <b>Conectividade contínua:</b> Mesmo se o seu celular estiver descarregado ou sem internet, você ainda poderá acessar suas conversas no WhatsApp através da nuvem.
                <br></br><br></br>
                2. <b>Configuração personalizada:</b> Você pode ajustar as configurações de acordo com suas preferências. Escolha como deseja receber notificações, organize suas conversas e gerencie contatos de forma eficiente.
                <br></br><br></br>
                3. <b>Segurança:</b> Apenas você terá acesso às suas conversas com os clientes. Nossa plataforma é projetada para proteger sua privacidade e manter suas informações seguras.
            </h5>
            
        </div>
    );
};

export default Precos;

