import React from "react";
import boyLeft from '../svg/boy-left.svg';
import robofeliz from '../svg/robofeliz.svg';
import robomsg from '../svg/robomsg.svg';
import robosendmsg from '../svg/robosendmsg.svg';
import '../styles/Home.css';

export const Home = () => {
    return (
        <div>
            <img src={boyLeft} alt='Boy Left' className='boy-left'></img>
            <div className='intro'>
                <h1>Atendimento eficiente, clientes satisfeitos!</h1>
                <h1>Deixe a automação cuidar do seu atendimento.</h1>
                <h5 style={{ marginTop: "20px" }}>
                    Expanda seu alcance, otimize seu tempo. Com nossa automação, foque no essencial enquanto
                    <br />
                    cuidamos do resto para você!
                    <br /><br />
                    Seja um <strong>sócio fundador</strong>. Ajude o Desenvolvimento do <strong>Chattzo</strong> e obtenha ofertas exclusivas, descontos exclusivos para sócio fundador, enquanto pessoas comum pagam valor integral você terá descontos e algumas outras exclusividades, Apoie o desenvolvimento do projeto!
                </h5>
            </div>
            <a href='/' className='bnt-price-home font-bold'>Preço</a>
            <br></br><br></br><br></br><br></br><br></br><br></br>
            <div className='bloco-container'>
                <div className='bloco background-primary'>
                    <img src={robofeliz} alt='Robô Sorrindo' className='robo'></img>
                    <h5 style={{ marginTop: "10px" }}>Chatbots 24/7: atendimento constante e automático</h5>
                </div>
                <div className='bloco background-primary'>
                    <img src={robosendmsg} alt='Robô com um avisão de papel em seu display' className='robo'></img>
                    <h5 style={{ marginTop: "10px" }}>Dispare mensagens em massa e expanda sua rede de contatos.</h5>
                </div>
                <div className='bloco background-primary'>
                    <img src={robomsg} alt='Robô com uma mensagem em seu display' className='robo'></img>
                    <h5 style={{ marginTop: "10px" }}>Menu com respostas por palavras-chave para atendimento ágil.</h5>
                </div>
            </div>
        </div>
    );
}

export default Home;