

function Footer() {
  return (


    <footer className='background-primary padding-bottom-10'>
      <ul className="nav justify-content-center border-bottom  font-bold">
        <li className="nav-item"><a href="/" className="nav-link px-2 text-color">Inicio</a></li>
        <li className="nav-item"><a href="/precos" className="nav-link px-2 text-color">Preços</a></li>
        <li className="nav-item"><a href="/about" className="nav-link px-2 text-color">Sobre</a></li>
        <li className="nav-item"><a href="/contact" className="nav-link px-2 text-color">Contato</a></li>
        <li className="nav-item"><a href="/" className="nav-link px-2 text-color disabled">Afiliados</a></li>
        <li className="nav-item"><a href="/" className="nav-link px-2 text-color disabled">FAQs</a></li>
      </ul>
      <li className="text-center text-color font-bold margin-top-10">© 2024 Chattzo, Inc. Todos os direitos reservados</li>
      
    </footer>
  );
}

export default Footer;
